import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import Sticky from "react-sticky-el";
import Hero from "../components/hero";

const ArticleTemplate = ({ data: { site, wpSupportArticle } }) => {
	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Support Articles",
				item: {
					url: `${siteUrl}/support-articles`,
					id: `${siteUrl}/support-articles`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${wpSupportArticle.title}`,
				item: {
					url: `${siteUrl}/support-articles/${wpSupportArticle.slug}`,
					id: `${siteUrl}/support-articles/${wpSupportArticle.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${wpSupportArticle.title} | The Facial Rehabilitation Centre`}
				description=""
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/support-articles/${wpSupportArticle.slug}`,
					title: `${wpSupportArticle.title} | The Facial Rehabilitation Centre`,
					description: "",
					images: [
						{
							url: `${wpSupportArticle.supportFields.image.localFile.publicURL}`,
							width: `${wpSupportArticle.supportFields.image.localFile.childImageSharp.original.width}`,
							height: `${wpSupportArticle.supportFields.image.localFile.childImageSharp.original.height}`,
							alt: `${wpSupportArticle.supportFields.image.altText}`,
						},
					],
				}}
			/>
			<section>
				<Hero
					subTitle={`Home > Support Articles > ${wpSupportArticle.title}`}
					title={wpSupportArticle.title}
					backgroundImage={
						wpSupportArticle.supportFields.image.localFile.childImageSharp
							.gatsbyImageData
					}
				/>
			</section>
			<section className="py-5 ">
				<Container>
					<Row className="justify-content-center">
						{wpSupportArticle.supportFields.haveQuickLinks === true && (
							<Col className="pb-7 pb-lg-0" lg={3}>
								{wpSupportArticle.supportFields.quickLinks.map((link) => (
									<a
										className="black-link mb-4 fs-4 ssp-semibold d-block"
										href={`${link.linkId}`}
									>
										{link.heading}
									</a>
								))}
							</Col>
						)}
						<Col lg={9}>
							<div className="blog-post-content">
								{parse(wpSupportArticle.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default ArticleTemplate;

export const pageQuery = graphql`
	query ArticleById($id: String!) {
		wpSupportArticle(id: { eq: $id }) {
			content
			title
			supportFields {
				haveQuickLinks
				quickLinks {
					heading
					linkId
				}
				date(formatString: "DD MMM YYYY")
				description
				image {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
